<template>
  <div class="progress-bar">
    <div
      v-for="(step, i) in steps"
      :key="'step' + i"
      :style="{ width: 100 / steps.length + '%' }"
      class="step-wrapper"
    >
      <div
        v-if="step.titleKey && step.status !== 'none'"
        :class="stepTitle(step)"
        v-text="step.titleKey"
      />
      <div v-if="step.value.length" class="step-value" v-html="step.value" />
      <div :class="['step', stepStatus(step)]">
        <IconSVG
          v-if="step.status === 'rejected'"
          :svg="IconCrossCircled"
          class="color-error title-icon"
        />
        <IconSVG
          v-else-if="step.status === 'passed'"
          :svg="IconCheckCircled"
          class="color-brand title-icon"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Global from 'src/mixins/Global';
import IconSVG from 'src/components/IconSVG.vue';
import IconCheckCircled from 'src/svg/check-circled.svg';
import IconCrossCircled from 'src/svg/cross-circled.svg';

@Component({
  components: {
    IconSVG,
  },
})
export default class TvWizardProgressBar extends mixins(Global) {
  IconCheckCircled = IconCheckCircled;
  IconCrossCircled = IconCrossCircled;
  @Prop({ required: true })
  steps!: Array<any>;

  stepStatus(step: { id: string; status: string }) {
    const lastStep = this.steps[this.steps.length - 1];
    if (step.status === 'passed') {
      if (lastStep.id === step.id) {
        return 'passed';
      } else {
        return 'current';
      }
    } else if (step.status === 'rejected') {
      return 'rejected';
    }
  }

  stepTitle(step: { id: string; value: string; status: string }) {
    if (step.value && step.status !== 'rejected') {
      return 'step-title-chosen';
    } else if (step.status === 'rejected') {
      return 'step-title-error';
    } else return 'step-title';
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/tv-wizard';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';

.progress-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 16px;
  border-radius: 12px;

  .step {
    top: 0;
    right: 0;
    height: 16px;
    border-radius: 12px;
  }

  .current,
  .passed,
  .rejected {
    background-color: var(--c-overlay-brand-fixed-2);
  }

  .title-icon {
    position: absolute;
    top: -4px;
    right: -6px;
    margin: 0;
  }

  .step-wrapper {
    position: relative;
    height: 100%;

    .step-value {
      position: absolute;
      top: -24px;
      left: 16px;
      opacity: 0.38;
      @include body2;
    }
  }
}

.light .progress-bar {
  background-color: var(--alpha-light-3);
}

.dark .progress-bar {
  background-color: var(--alpha-dark-3);
}
</style>
