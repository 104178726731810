<template>
  <div class="theme">
    <div class="bg" :style="'background-image:url(' + imageUrl + ')'" />
    <div class="container">
      <div class="wrapper">
        <h1 class="title-wrap" v-html="title" />
        <div v-if="currentStep === 'start'" class="choose-container">
          <div class="h5" v-html="getTranslation('choose_manufactor')" />
          <div class="choose-wrapper first-wrapper">
            <div class="choose-row">
              <div
                v-for="(company, i) in TvManufacturers"
                :key="'company' + i"
                class="choose-block choose-block-img"
                @click="chooseTV(company)"
              >
                <ImageWithHash
                  :src="
                    '/public/images/icons/tv/' +
                    (company === 'sony'
                      ? $store.theme === 'light'
                        ? 'sony-black'
                        : 'sony-white'
                      : company) +
                    '.svg'
                  "
                  :alt="company"
                />
              </div>
            </div>
            <div
              class="choose-block choose-block-wide"
              @click="chooseTV(getTranslation('another_tv_model'))"
              v-html="getTranslation('another_tv_model')"
            />
          </div>
        </div>

        <div v-if="currentStep === 'SamsungFirstStep'" class="choose-container">
          <TvWizardButtonBack :currentStep="currentStep" @goTo="currentStep = 'start'" />
          <div class="h5 title-wrap" v-html="getTranslation('if_samsung_app')" />
          <div class="choose-wrapper">
            <div class="choose-row">
              <div
                class="choose-block"
                @click="
                  (currentStep = 'SamsungInstructions'), (steps[1].value = getTranslation('yes'))
                "
                v-html="getTranslation('yes')"
              />
              <div
                class="choose-block"
                @click="
                  (currentStep = 'UnavailableInstructions'),
                    (steps[1].value = getTranslation('no')),
                    (steps[1].status = 'rejected')
                "
                v-html="getTranslation('no')"
              />
              <div
                v-if="!isKt"
                class="choose-block"
                @click="toModelChoosing"
                v-html="getTranslation('dont_know')"
              />
            </div>
          </div>
          <div v-if="content.SamsungFirstStep.length" class="hidden-content-container">
            <Accordion
              v-for="(item, i) in content.SamsungFirstStep"
              :key="i + 'Sfirst'"
              class="mb-32"
              :component-name="item.hiddenComponents"
            />
          </div>
        </div>

        <div v-if="currentStep === 'SamsungSecondStep'" class="choose-container">
          <TvWizardButtonBack
            :currentStep="currentStep"
            @goTo="(currentStep = 'SamsungFirstStep'), steps.pop()"
          />
          <div class="h5 mb-24" v-html="getTranslation('pick_tv_model')" />

          <div class="choose-wrapper">
            <InputText
              v-model="TvModel"
              size="medium"
              :status="TvModel && !modelNameCorrect && modelCheckedIncorrect ? 'error' : undefined"
              class="input-block"
              :placeholder="getTranslation('tv_model')"
              :description="`${getTranslation('for_example')} ${getTranslation('tv_model_num')}`"
            />
            <div
              v-if="TvModel && !modelNameCorrect && modelCheckedIncorrect"
              class="error mb-16 translation-link"
              v-html="getTranslation('tv_model_check')"
            />
            <ButtonDefault @click="checkTvModel">
              <span v-html="getTranslation('next_reg')" />
            </ButtonDefault>
          </div>

          <div v-if="content.SamsungSecondStep.length" class="hidden-content-container">
            <Accordion
              v-for="(item, i) in content.SamsungSecondStep"
              :key="i + 'Second'"
              class="mb-32"
              :component-name="item.hiddenComponents"
            />
          </div>
        </div>

        <TvWizardInstructions
          v-if="currentStep === 'SamsungInstructions'"
          class="choose-container"
          :currentStep="currentStep"
          :text="availableText"
          :instructions="content.SamsungInstructions"
          :accordionItems="content.SamsungSuccess"
          type="success"
          @goTo="backFromResults"
          @toStart="(currentStep = 'start'), steps[2] ? steps.pop() : ''"
        />

        <div v-if="currentStep === 'LGFirstStep'" class="choose-container">
          <TvWizardButtonBack :currentStep="currentStep" @goTo="currentStep = 'start'" />
          <div class="h5 title-wrap" v-html="getTranslation('if_lg_store')" />
          <div class="choose-wrapper">
            <div class="choose-row">
              <div
                class="choose-block"
                @click="(currentStep = 'LGInstructions'), (steps[1].value = 'LG Content Store')"
              >
                LG Content Store
              </div>
              <div
                class="choose-block"
                @click="(currentStep = 'LGInstructions'), (steps[1].value = 'LG Smart World')"
              >
                LG Smart World
              </div>
              <div
                class="choose-block"
                @click="
                  (currentStep = 'UnavailableInstructions'),
                    (steps[1].value = getTranslation('no')),
                    (steps[1].status = 'rejected')
                "
                v-html="getTranslation('no')"
              />
            </div>
          </div>
          <div v-if="content.LGFirstStep.length" class="hidden-content-container">
            <Accordion
              v-for="(item, i) in content.LGFirstStep"
              :key="i + 'lgStep'"
              class="mb-32"
              :component-name="item.hiddenComponents"
            />
          </div>
        </div>

        <TvWizardInstructions
          v-if="currentStep === 'LGInstructions'"
          class="choose-container"
          :currentStep="currentStep"
          :text="availableText"
          :instructions="content.LGInstructions"
          :accordionItems="content.LGSuccess"
          type="success"
          @goTo="backFromResults"
          @toStart="currentStep = 'start'"
        />

        <div v-if="currentStep === 'AndroidFirstStep'" class="choose-container">
          <TvWizardButtonBack :currentStep="currentStep" @goTo="currentStep = 'start'" />
          <div class="h5 title-wrap" v-html="getTranslation('if_google_play')" />
          <div class="choose-wrapper">
            <div class="choose-row">
              <div
                class="choose-block"
                @click="
                  (currentStep = 'AndroidInstructions'), (steps[1].value = getTranslation('yes'))
                "
                v-html="getTranslation('yes')"
              />
              <div
                class="choose-block"
                @click="
                  (currentStep = 'UnavailableInstructions'),
                    (steps[1].value = getTranslation('no')),
                    (steps[1].status = 'rejected')
                "
                v-html="getTranslation('no')"
              />
            </div>
          </div>
          <div v-if="content.AndroidFirstStep.length" class="hidden-content-container">
            <Accordion
              v-for="(item, i) in content.AndroidFirstStep"
              :key="i + 'androidStep'"
              class="mb-32"
              :component-name="item.hiddenComponents"
            />
          </div>
        </div>

        <TvWizardInstructions
          v-if="currentStep === 'AndroidInstructions'"
          class="choose-container"
          :currentStep="currentStep"
          :text="availableText"
          :instructions="content.AndroidInstructions"
          :accordionItems="content.AndroidSuccess"
          type="success"
          @goTo="backFromResults"
          @toStart="currentStep = 'start'"
        />
      </div>

      <TvWizardInstructions
        v-if="currentStep === 'UnavailableInstructions'"
        class="choose-container"
        :currentStep="currentStep"
        :text="unavailableText"
        :instructions="content.UnavailableInstructions"
        :accordionItems="content.UnavailableComponents"
        type="error"
        @goTo="backFromResults"
        @toStart="toStart(), steps[2] ? steps.pop() : ''"
      />

      <div class="steps">
        <div class="steps-inner">
          <TvWizardProgressBar :steps="steps" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import AddResizeListener from 'src/mixins/AddResizeListener';
import Accordion from './Accordion.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconSVG from 'src/components/IconSVG.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import TvWizardButtonBack from 'src/components/tv-wizard/TvWizardButtonBack.vue';
import TvWizardResultHeading from 'src/components/tv-wizard/TvWizardResultHeading.vue';
import TvWizardInstructions from 'src/components/tv-wizard/TvWizardInstructions.vue';
import TvWizardProgressBar from 'src/components/tv-wizard/TvWizardProgressBar.vue';
import { wlDetector } from 'src/utils';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: {
    ImageWithHash,
    IconSVG,
    Accordion,
    ButtonDefault,
    InputText,
    TvWizardButtonBack,
    TvWizardResultHeading,
    TvWizardInstructions,
    TvWizardProgressBar,
  },
})
export default class TvWizard extends mixins(Global, CmsComponent, AddResizeListener) {
  IconAngleLeft = IconAngleLeft;
  TvManufacturers = ['samsung', 'lg', 'sony', 'philips'];
  steps = [
    {
      id: '1',
      status: 'current',
      titleKey: this.getTranslation('manufacturer'),
      value: '',
    },
    {
      id: '2',
      status: 'none',
      titleKey: this.getTranslation('app_store'),
      value: '',
    },
  ];
  currentStep = 'start';
  TvModel = '';
  availableModels = ['F', 'H', 'J', 'K', 'M', 'N', 'Q', 'R', 'T', 'S'];
  windowWidth = 0;
  minTvModelLength = 9;
  letterPosition = 4;
  modelCheckedIncorrect = false;

  content?: {
    title?: TTranslations;
    imageLight?: TTranslations<TImageCMS>;
    imageDark?: TTranslations<TImageCMS>;
    availableText?: TTranslations;
    unavailableText?: TTranslations;
    SamsungFirstStep?: Array<{ hiddenComponents?: string }>;
    SamsungSecondStep?: Array<{ hiddenComponents?: string }>;
    SamsungInstructions?: string;
    SamsungSuccess?: Array<{ hiddenComponents?: string }>;
    LGFirstStep?: Array<{ hiddenComponents?: string }>;
    LGInstructions?: string;
    LGSuccess?: Array<{ hiddenComponents?: string }>;
    AndroidFirstStep?: Array<{ hiddenComponents?: string }>;
    AndroidInstructions?: string;
    AndroidSuccess?: Array<{ hiddenComponents?: string }>;
    UnavailableInstructions?: string;
    UnavailableComponents?: Array<{ hiddenComponents?: string }>;
  } | null = null;

  get title() {
    return this.getByLanguage(this.content?.title);
  }

  get imageUrl() {
    if (!this.content) {
      return '';
    }
    const image = this.content[this.$store.theme === 'light' ? 'imageLight' : 'imageDark'] || null;
    return image ? this.getByLanguage(image)?.url || '' : '';
  }

  get availableText() {
    return this.getByLanguage(this.content?.availableText) || '';
  }

  get unavailableText() {
    return this.getByLanguage(this.content?.unavailableText) || '';
  }

  get modelNameCorrect() {
    return (
      this.TvModel.length >= this.minTvModelLength &&
      this.TvModel.charAt(this.letterPosition).match(/[a-z]/i) &&
      !/\s/.test(this.TvModel)
    );
  }

  get isKt() {
    return wlDetector().isKt;
  }

  async mounted() {
    this.windowWidth = window.innerWidth;
  }

  @Watch('currentStep')
  onCurrentStepChange(step: string | number) {
    if (step === 'start') {
      this.steps[0].status = 'current';
      this.steps[1].status = 'none';
      this.steps.forEach((item) => (item.value = ''));
    } else if (
      step === 'SamsungFirstStep' ||
      step === 'LGFirstStep' ||
      step === 'AndroidFirstStep'
    ) {
      this.steps[0].status = 'passed';
      this.steps[1].status = 'current';
      this.steps[1].value = '';
      if (this.steps[2]) {
        this.steps[2].status = 'none';
      }
    } else if (step === 'SamsungSecondStep') {
      this.steps.push({
        id: '3',
        status: 'none',
        titleKey: 'model',
        value: '',
      });
      this.steps[0].status = 'passed';
      this.steps[1].status = 'passed';
      this.steps[2].status = 'current';
    } else if (step === 'LGInstructions' || step === 'AndroidInstructions') {
      this.steps[0].status = 'passed';
      this.steps[1].status = 'passed';
      if (this.steps[2]) {
        this.steps[2].status = 'none';
      }
    } else if (step === 'SamsungInstructions') {
      if (this.steps[2] && this.steps[2].status === 'current') {
        this.steps[2].status = 'passed';
      } else {
        this.steps[1].status = 'passed';
      }
    }
  }

  @Watch('TvModel')
  onTvModelChange(value: string) {
    if (value === '') {
      this.modelCheckedIncorrect = false;
    }
  }

  chooseTV(company: string) {
    if (company === 'samsung') {
      this.currentStep = 'SamsungFirstStep';
      this.steps[0].value = 'Samsung';
    } else if (company === 'lg') {
      this.currentStep = 'LGFirstStep';
      this.steps[0].value = 'LG';
    } else {
      this.currentStep = 'AndroidFirstStep';
      this.steps[0].value = company;
    }
  }

  checkTvModel() {
    if (this.modelNameCorrect) {
      const modelArray = this.TvModel.split('');
      if (this.availableModels.includes(modelArray[4].toUpperCase())) {
        this.currentStep = 'SamsungInstructions';
        this.steps[1].status = 'passed';
        this.steps[2].status = 'passed';
        this.steps[2].value = this.TvModel;
      } else {
        this.currentStep = 'UnavailableInstructions';
        this.steps[1].status = 'passed';
        this.steps[2].status = 'rejected';
        this.steps[2].value = this.TvModel;
      }
      this.modelCheckedIncorrect = false;
    } else {
      this.modelCheckedIncorrect = true;
    }
  }

  backFromResults() {
    if (this.steps[2]) {
      this.steps[2].value = '';
      this.steps.pop();
    }
    if (this.steps[0].value === 'Samsung') {
      if (this.steps[1].value === this.getTranslation('dont_know')) {
        this.currentStep = 'SamsungSecondStep';
      } else {
        this.currentStep = 'SamsungFirstStep';
      }
    } else if (this.steps[0].value === 'LG') {
      this.currentStep = 'LGFirstStep';
    } else {
      this.currentStep = 'AndroidFirstStep';
    }
  }

  toStart() {
    this.currentStep = 'start';
    document.querySelector('.scroll-layout-wrap')?.scrollTo(0, 0);
  }

  toModelChoosing() {
    this.currentStep = 'SamsungSecondStep';
    this.steps[1].value = this.getTranslation('dont_know');
    this.TvModel = '';
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/tv-wizard';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.bg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: var(--z-rock-bottom);
  width: 100%;
  height: 1100px;
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;

  @include mobile-and-tablet {
    display: none;
  }
}

.title-wrap {
  max-width: 650px;

  @include desktop-s {
    max-width: 512px;
  }
}

.content-wrap {
  max-width: 800px;
}

.container {
  position: relative;
  width: 100%;
  max-width: calc(1440px - 128px);
  padding: 0 0 160px;
  margin: 0 auto 16px;
  line-height: 1.5;

  @include desktop-s {
    padding: 0 64px 160px;
  }

  @include mobile-and-tablet {
    padding: 0 24px;
  }

  .wrapper {
    margin-bottom: 72px;
  }

  .choose-container {
    margin-top: 80px;

    .hidden-content-container {
      width: 100%;
      max-width: 800px;
      margin-top: 48px;
    }

    @include mobile-and-tablet {
      margin-top: 48px;
    }

    .choose-wrapper {
      display: grid;
      justify-items: flex-start;

      @include mobile {
        justify-content: stretch;
        justify-items: stretch;
      }

      &.first-wrapper {
        justify-content: start;
        @include tablet {
          .choose-row {
            grid-template-columns: 1fr 1fr;
          }
        }
        @include mobile-and-tablet {
          justify-content: stretch;
          justify-items: stretch;

          .choose-row {
            grid-auto-flow: row;
          }
        }
      }

      .choose-row {
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 32px;

        @include desktop-s {
          grid-column-gap: 16px;
        }

        @include mobile {
          grid-auto-flow: row;
        }
      }

      .choose-block {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 168px;
        height: 72px;
        margin-top: 24px;
        cursor: pointer;
        border-style: solid;
        border-width: 2px;
        border-radius: 8px;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: var(--z-rock-bottom);
          width: 100%;
          height: 100%;
        }

        @include devices-with-hover {
          &:hover {
            background: var(--c-overlay-brand-custom-1);
            transition: all 0.15s ease-out;
          }
        }

        @include mobile {
          width: 100%;
          max-width: 100%;
          max-height: 96px;
        }
      }

      .choose-block-img {
        width: 200px;
        height: 200px;

        @include desktop-s {
          max-width: 186px;
          max-height: 186px;

          img {
            max-width: 128px;
            height: 32px;
          }
        }

        @include mobile-and-tablet {
          width: 100%;
          max-width: 100%;
          max-height: 96px;
        }
      }

      .choose-block-wide {
        width: 100%;
        margin-top: 32px;

        @include mobile-and-tablet {
          height: 96px;
        }
      }

      .model-input {
        width: 288px;

        @include mobile-and-tablet {
          width: 100%;
        }
      }
    }
  }
}

.steps {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 96px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  @include mobile-and-tablet {
    display: none;
  }

  .steps-inner {
    padding: 64px 64px 20px;
  }
}

.input-block {
  min-width: 288px;
  margin-bottom: 16px;

  @include mobile {
    width: 100%;
    min-width: 0;
    margin-bottom: 24px;
  }
}

// --------------------------------------------
// Theme Colors
// --------------------------------------------
.light {
  .container {
    .choose-block {
      background-color: var(--c-light-100);
      border-color: var(--c-light-300);

      &::after {
        background: var(--c-light-100);
      }

      @include devices-with-hover {
        &:hover {
          border-color: var(--c-light-brand);
        }
      }
    }

    .steps {
      background-color: var(--c-light-100);
    }
  }
}

.dark {
  .container {
    .choose-block {
      background-color: var(--c-dark-200);
      border-color: var(--alpha-dark-5);

      &::after {
        background: var(--c-dark-100);
      }

      @include devices-with-hover {
        &:hover {
          border-color: var(--c-dark-brand);
        }
      }
    }

    .steps {
      background-color: var(--c-dark-150);
    }
  }
}
</style>
