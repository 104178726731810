<template>
  <IconSVG
    :svg="type === 'success' ? IconCheckCircled : IconCrossCircled"
    class="title-icon"
    :class="`color-${type}`"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconCheckCircled from 'src/svg/check-circled.svg';
import IconCrossCircled from 'src/svg/cross-circled.svg';

@Component({
  components: {
    IconSVG,
  },
})
export default class TvWizardResultHeading extends Vue {
  IconAngleLeft = IconAngleLeft;
  IconCheckCircled = IconCheckCircled;
  IconCrossCircled = IconCrossCircled;

  @Prop({ required: true })
  type!: string;
}
</script>

<style lang="scss">
@import 'src/styles/pages/tv-wizard';
</style>

<style lang="scss" scoped>
.title-icon {
  flex: none;
  margin-top: 4px;
  margin-right: 8px;
}
</style>
