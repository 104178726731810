<template>
  <div>
    <TvWizardButtonBack :currentStep="currentStep" @goTo="$emit('goTo')" />
    <div class="flex mb-32">
      <TvWizardResultHeading :type="type" />
      <div class="h5" v-html="text" />
    </div>
    <div class="choose-wrapper content-wrap">
      <div v-html="getByLanguage(instructions)" />
      <div v-if="accordionItems.length" class="hidden-content-container">
        <Accordion
          v-for="(item, i) in accordionItems"
          :key="'accordion' + i"
          class="mb-32"
          :component-name="item.hiddenComponents"
        />
      </div>
      <ButtonDefault class="button-to-start" @click="$emit('toStart')">
        <span v-html="getTranslation('start_over')" />
      </ButtonDefault>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Global from 'src/mixins/Global';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleLeft from 'src/svg/angle-left.svg';
import TvWizardButtonBack from 'src/components/tv-wizard/TvWizardButtonBack.vue';
import TvWizardResultHeading from 'src/components/tv-wizard/TvWizardResultHeading.vue';
import Accordion from 'src/components/cms/Accordion.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { TTranslations } from 'src/store/translations/types';

@Component({
  components: {
    IconSVG,
    TvWizardButtonBack,
    TvWizardResultHeading,
    Accordion,
    ButtonDefault,
  },
})
export default class TvWizardInstructions extends mixins(Global) {
  IconAngleLeft = IconAngleLeft;

  @Prop({ required: true })
  currentStep!: string;

  @Prop({ required: true })
  text!: string;

  @Prop({ required: true })
  instructions!: TTranslations;

  @Prop({ required: true })
  accordionItems!: Array<any>; // TODO add proper type

  @Prop({ required: true })
  type!: string;
}
</script>

<style lang="scss">
@import 'src/styles/pages/tv-wizard';
</style>

<style lang="scss" scoped>
.hidden-content-container {
  width: 100%;
  max-width: 800px;
  margin-top: 48px;
}

.button-to-start {
  margin-top: 48px;
}
</style>
