import { render, staticRenderFns } from "./TvWizardProgressBar.vue?vue&type=template&id=15f39c5c&scoped=true&"
import script from "./TvWizardProgressBar.vue?vue&type=script&lang=ts&"
export * from "./TvWizardProgressBar.vue?vue&type=script&lang=ts&"
import style0 from "./TvWizardProgressBar.vue?vue&type=style&index=0&id=15f39c5c&prod&lang=scss&"
import style1 from "./TvWizardProgressBar.vue?vue&type=style&index=1&id=15f39c5c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f39c5c",
  null
  
)

export default component.exports