<template>
  <button type="button" class="mb-24 link link-icon nav-icon-colored" @click="$emit('goTo')">
    <IconSVG :svg="IconAngleLeft" />
    <span class="text" v-html="getTranslation('back')" />
  </button>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Global from 'src/mixins/Global';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleLeft from 'src/svg/angle-left.svg';

@Component({
  components: {
    IconSVG,
  },
})
export default class TvWizardButtonBack extends mixins(Global) {
  IconAngleLeft = IconAngleLeft;

  @Prop({ required: true })
  currentStep!: string;
}
</script>

<style lang="scss">
@import 'src/styles/pages/tv-wizard';
</style>
